
import { computed, defineComponent, provide } from 'vue'
import Product from '@/components/product/index.vue'
import { useStore } from 'vuex'

export default defineComponent({
	name: 'PageProductAdd',

	components: { Product },

	setup() {
		const store = useStore()

		const isUserAllowedChange = computed(() => {
			const userRoleIds = store.state.auth?.userRoleIds
			const allowedRoles = [4, 9]
			let isUserCanChange = false
			for (const userRoleId of userRoleIds) {
				if (allowedRoles.includes(userRoleId)) {
					isUserCanChange = true
				}
			}
			return isUserCanChange
		})

		provide('isUserAllowedChange', isUserAllowedChange)
	}
})
